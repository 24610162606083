import React from 'react';
import headerBackground from "../../../../assets/homepage/homepage-video-background.mp4";
import headerImage from "../../../../assets/homepage/homepage-header-image.png";
import headerImage2x from "../../../../assets/homepage/homepage-header-image@2x.png";
import videoPlaceholder from '../../../../assets/homepage/homepage-video-thumbnail.png';

import * as styles from './HomepageHeader.module.scss';
import { Link } from "react-router-dom";

const HomepageHeader = ({bannerConsent}) => (
  <header className={bannerConsent ? styles['home-header'] : styles['home-header--has-banner']}>
    <video
      poster={videoPlaceholder}
      autoPlay loop muted playsInline className={styles['home-header__video']}>
      <source src={headerBackground} type="video/mp4"/>
    </video>

    <div className={styles['home-header-container']}>
      <img  srcSet={`${headerImage} 1x, ${headerImage2x} 2x`} alt="" className={styles['home-header__img']}/>
      <div className={styles['home-header__content']}>
        <h1 className={styles['home-header__title']}>Video. Reimagined.</h1>
        <p className={styles['home-header__subtitle']}>Deliver successful video experiences without compromise.</p>
        <Link to='/contact' className={styles['home-header__link']}>Get in touch</Link>
      </div>
    </div>
  </header>
);

export default HomepageHeader;