import React, { Fragment } from 'react';

import './MobileMenu.scss';
import { Link, NavLink } from 'react-router-dom';

const MobileMenu = (props) => {

  const toggleMenuSection = e => {
    e.currentTarget.nextElementSibling.classList.toggle('mobile-menu__section--hidden');
    e.currentTarget.children[1].classList.toggle('mobile-menu__header__expand-icon--closed');
  };

  console.log(props)

  return (
    <div className={'mobile-menu-container ' + (!props.bannerConsent ? 'mobile-menu-container--has-banner' : '')}>
      <div className='mobile-menu'>
        {props.navigationLinks.map((item, index) => (
          <Fragment key={index}>
            <div className='mobile-menu__header' onClick={toggleMenuSection}>
              <span className='mobile-menu__header__text'>{item.category}</span>
              <div className='mobile-menu__header__expand-icon'>
                <i className="material-icons">
                  expand_more
                </i>
                <i className="material-icons">
                  expand_less
                </i>
              </div>
            </div>
            <ul className='mobile-menu__section'>
              {item.links.map((link, index) => (
                <li key={index} onClick={props.closeMobileNav}>
                  <NavLink to={link.path} className='mobile-menu__link' activeClassName='mobile-menu__link--active'>
                    {link.text}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
        <div className={'mobile-demo-btn-container'}>
          <Link to="/contact"
                className={'mobile-demo-btn'}
                onClick={props.closeMobileNav}>REQUEST A DEMO
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;