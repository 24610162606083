import React from 'react';

import './TopBanner.scss';

import jwp_conn_logo from '../../assets/banner/jwp_conn_logo_white.webp';

const TopBanner = ({ bannerConsent, setBannerConsent }) => {
    const hideBanner = () => {
        setBannerConsent(true);
    }

    if (bannerConsent) {
        return null;
    }

    return (
        <div className="top-banner">
            <div className="top-banner__wrapper">
                <div className="top-banner__close-btn" onClick={hideBanner}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 -1 28 29" preserveAspectRatio="none">
                        <path
                            d="M2.71 26.79c.312.314.743.508 1.22.508s.908-.194 1.22-.507L16 15.941l10.86 10.85c.312.312.744.505 1.22.505.953 0 1.725-.772 1.725-1.725 0-.476-.193-.908-.505-1.22l-10.86-10.85 10.85-10.86c.279-.305.45-.713.45-1.16 0-.95-.77-1.72-1.72-1.72-.448 0-.856.171-1.162.452l.001-.001-10.86 10.85L5.139.212c-.316-.344-.767-.56-1.27-.56-.95 0-1.72.77-1.72 1.72 0 .502.215.954.558 1.268l.001.001 10.85 10.86-10.85 10.86c-.311.311-.503.741-.503 1.215s.192.904.503 1.215z"></path>
                    </svg>
                </div>
                <div className="top-banner__info-section">
                    <div className="top-banner__image">
                        <img src={jwp_conn_logo} alt="Jwp Conn Logo"/>
                    </div>
                    <div className="top-banner__title">JW Player and Connatix Merge to Transform the Digital Video
                        Economy
                    </div>
                    <div className="top-banner__action-btn">
                        <a target="_blank" rel="noopener noreferrer"
                           href="https://www.prnewswire.com/news-releases/connatix-and-jw-player-merge-to-create-the-industrys-largest-video-technology-and-monetization-platform-302271377.html?tc=eml_cleartime"
                        >Read the Press Release</a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TopBanner;