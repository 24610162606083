import React, { Component } from "react";
import styles from "./Footer.module.scss";
import Container from "../../hoc/Container/Container";
import { NavLink } from "react-router-dom";

import logo from "../../assets/footer/connatix-logo.svg";
import facebookLogo from "../../assets/footer/facebook.png";
import instagramLogo from "../../assets/footer/instagram.png";
import linkedinLogo from "../../assets/footer/linkedin.png";
import twitterLogo from "../../assets/footer/twitter.png";

import googlePartnerLogo from "../../assets/footer/logos/google-partner-logo.png";
import googlePartner2Logo from "../../assets/footer/logos/google-partner2-logo.png";
import humanLogo from "../../assets/footer/logos/human-logo.png";
import iabLabLogo from "../../assets/footer/logos/iab-lab-logo.png";
import iabVerifiedLogo from "../../assets/footer/logos/iab-verified-logo.png";
import iabLogo from "../../assets/footer/logos/iab-logo.png";
import newsGuardLogo from "../../assets/footer/logos/news-guard-logo.png";
import tagLogo from "../../assets/footer/logos/tag-logo.png";
import w3cLogo from "../../assets/footer/logos/w3c-logo.png";
import wpVipLogo from "../../assets/footer/logos/wp-vip-logo.png";

class Footer extends Component {
  setHeaderMenuActive = (headerMenuCategory) => {
    const currentActiveItem =
      document.getElementsByClassName("menu-header-active")[0];
    if (currentActiveItem) {
      currentActiveItem.classList.remove("menu-header-active");
    }
    const newActiveItem = document.getElementById(headerMenuCategory);
    if (newActiveItem) {
      newActiveItem.classList.add("menu-header-active");
    }
  };

  footerLogos = [
    {
      imgSrc: googlePartner2Logo,
      imgAlt: "Google Partner2 Logo",
      imgWidth: "150px",
      imgHeight: "56px",
      link: "https://www.google.com/ads/publisher/partners/",
    },
    {
      imgSrc: iabLabLogo,
      imgAlt: "Iab Lab Logo",
      imgWidth: "40px",
      imgHeight: "29px",
      link: "https://iabtechlab.com/about-the-iab-tech-lab/iab-tech-lab-members/",
    },
    {
      imgSrc: iabVerifiedLogo,
      imgAlt: "Iab Verified Logo",
      imgWidth: "55px",
      imgHeight: "47px",
      link: "https://iabtechlab.com/compliance-programs/compliant-companies/",
    },

    {
      imgSrc: iabLogo,
      imgAlt: "Iab Logo",
      imgWidth: "40px",
      imgHeight: "20px",
      link: "https://www.iab.com/member-directory/#general",
    },
    {
      imgSrc: humanLogo,
      imgAlt: "Human Logo",
      imgWidth: "100px",
      imgHeight: "22px",
      link: "https://www.humansecurity.com/",
    },
    {
      imgSrc: tagLogo,
      imgAlt: "Tag Logo",
      imgWidth: "45px",
      imgHeight: "46px",
      link: "https://www.tagtoday.net/registry",
    },

    {
      imgSrc: newsGuardLogo,
      imgAlt: "NewsGuard Logo",
      imgWidth: "134px",
      imgHeight: "19px",
      link: "https://www.newsguardtech.com/",
    },
    {
      imgSrc: wpVipLogo,
      imgAlt: "WP VIP Logo",
      imgWidth: "120px",
      imgHeight: "36px",
      link: "https://wpvip.com/partner/connatix/",
    },

    {
      imgSrc: w3cLogo,
      imgAlt: "W3c Logo",
      imgWidth: "40px",
      imgHeight: "20px",
      link: "https://www.w3.org/Consortium/Member/List",
    },
    {
      imgSrc: googlePartnerLogo,
      imgAlt: "Google Partner Logo",
      imgWidth: "150px",
      imgHeight: "56px",
      link: "https://support.google.com/admanager/answer/186110",
    },
  ];

  render() {
    return (
      <footer className={styles["footer"]}>
        <Container>
          <div className={styles["footer-content"]}>
            <div className={styles["footer-logos"]}>
              <img
                src={logo}
                className={styles["connatix-logo"]}
                alt="Connatix"
              />
              <div className={styles["footer-menu__social"]}>
                <a
                  href="https://www.linkedin.com/company/connatix"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles["footer-menu__link"]}
                >
                  <img src={linkedinLogo} alt="Linkedin" />
                </a>
                <a
                  href="https://twitter.com/connatix"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles["footer-menu__link"]}
                >
                  <img src={twitterLogo} alt="Twitter" />
                </a>
                <a
                  href="https://www.facebook.com/Connatix/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles["footer-menu__link"]}
                >
                  <img src={facebookLogo} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/connatix"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles["footer-menu__link"]}
                >
                  <img src={instagramLogo} alt="Instagram" />
                </a>
              </div>
            </div>
            <div className={styles["footer-middle"]}>
              <div className={styles["footer-text"]}>
                <div className={styles["footer-title"]}>Video. Reimagined.</div>
                <div className={styles["footer-description"]}>
                  Deliver successful video experiences without compromise.
                </div>
              </div>
              <div className={styles["footer-menu"]}>
                <nav className={styles["footer-menu__navigation"]}>
                  {/* PUBLISHERS */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      Publishers
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Publishers")}>
                      <NavLink
                        to="/video-delivery"
                        className={styles["footer-menu__link"]}
                      >
                        Video Delivery
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Publishers")}>
                      <NavLink
                        to="/video-monetization"
                        className={styles["footer-menu__link"]}
                      >
                        Video Monetization
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Publishers")}>
                      <NavLink
                        to="/video-analytics"
                        className={styles["footer-menu__link"]}
                      >
                        Video Analytics
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Publishers")}>
                      <NavLink
                        to="/video-creation"
                        className={styles["footer-menu__link"]}
                      >
                        Video Creation
                      </NavLink>
                    </li>
                  </ul>
                  {/* ADVERTISERS */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      Advertisers
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Advertisers")}>
                      <NavLink
                        className={styles["footer-menu__link"]}
                        to="/advertisers"
                      >
                        Advertisers
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Advertisers")}>
                      <NavLink
                        to="/deep-contextual"
                        className={styles["footer-menu__link"]}
                      >
                        Deep Contextual
                      </NavLink>
                    </li>
                  </ul>

                  {/* NEWS AND VIEWS */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      News & Views
                    </li>
                    <li
                      onClick={() => this.setHeaderMenuActive("News & Views")}
                    >
                      <NavLink
                        to="/blog"
                        className={styles["footer-menu__link"]}
                      >
                        Blog
                      </NavLink>
                    </li>
                    <li
                      onClick={() => this.setHeaderMenuActive("News & Views")}
                    >
                      <NavLink
                        to="/press"
                        className={styles["footer-menu__link"]}
                      >
                        Press
                      </NavLink>
                    </li>
                  </ul>

                  {/* ABOUT US */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      About Us
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("About Us")}>
                      <NavLink
                        to="/leadership"
                        className={styles["footer-menu__link"]}
                      >
                        Leadership
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("About Us")}>
                      <NavLink
                        to="/careers"
                        className={styles["footer-menu__link"]}
                      >
                        Careers
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("About Us")}>
                      <NavLink
                        to="/contact"
                        className={styles["footer-menu__link"]}
                      >
                        Contact us
                      </NavLink>
                    </li>
                  </ul>

                  {/* INFORMATION */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      Information
                    </li>
                    <li>
                      <a
                        href="https://support.connatix.com/hc/en-us"
                        rel="noreferrer noopener"
                        target="_blank"
                        className={styles["footer-menu__link"]}
                      >
                        Developer support
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://portal.connatix.com/login"
                        rel="noreferrer noopener"
                        target="_blank"
                        className={styles["footer-menu__link"]}
                      >
                        Platform login
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://status.connatix.com/"
                        rel="noreferrer noopener"
                        target="_blank"
                        className={styles["footer-menu__link"]}
                      >
                        System status
                      </a>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("About Us")}>
                      <NavLink
                        to="/copyright"
                        className={styles["footer-menu__link"]}
                      >
                        Copyright
                      </NavLink>
                    </li>
                  </ul>

                  {/* PRIVACY POLICY */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      Privacy Policy
                    </li>

                    <li onClick={() => this.setHeaderMenuActive("About Us")}>
                      <NavLink
                        to="/privacy-policy"
                        className={styles["footer-menu__link"]}
                      >
                        General/Video Platforms
                      </NavLink>
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("About Us")}>
                      <NavLink
                        to="/job-applicant-privacy-policy"
                        className={styles["footer-menu__link"]}
                      >
                        Job Applicant
                      </NavLink>
                    </li>
                  </ul>

                  {/* PRICING */}
                  <ul className={styles["footer-menu__section"]}>
                    <li className={styles["footer-menu__section__title"]}>
                      Pricing
                    </li>
                    <li onClick={() => this.setHeaderMenuActive("Pricing")}>
                      <NavLink
                        className={styles["footer-menu__link"]}
                        to="/pricing"
                      >
                        Pricing
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className={styles["footer__copyright"]}>
              &copy; {new Date().getFullYear()} Connatix Holdings Inc., All
              Rights Reserved.
            </div>
          </div>
          <div className={styles["footer-external-logos"]}>
            {this.footerLogos.map((logo) => (
              <a
                key={logo.imgSrc}
                href={logo.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: logo.imgWidth, height: logo.imgHeight }}
              >
                <img src={logo.imgSrc} alt={logo.imgAlt} />
              </a>
            ))}
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
